
:root {
    color-scheme: light dark;
}

body {
    margin: 0px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    font-family: "Open Sans", "Roboto", "Helvetica", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    overflow: auto;


}

body::-webkit-scrollbar {
    width: 8px;
    display: block;
}

body::-webkit-scrollbar-track {
    background: transparent;
    display: fixed;
    height: 100%;
}

body::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.70);
}
/* 
@media(prefers-color-scheme: dark) {
    body::-webkit-scrollbar-thumb {
        background: #FFFFFF;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 0 2px rgba(125, 125, 125, 0.70);
    }
} */

html {
    height: -webkit-fill-available;
}

code {
    font-family: "Open Sans", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


#root {
   
}


